import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import secureStorage from './../../../config/encrypt';
import InputField from './../../elements/InputField';
import Button from '../../elements/Button'
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
class ResetAccountBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            resetMaxAttemptdata:{}
        };
    }
    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            this.afterLoginSetState(loginResponse)
        } else {
            this.setState({ redirect: true })
        }
    }
    componentDidMount = async () => {
        await this.updateReduxData();
    }
    afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "admin") {
            this.setState({ redirect: '/' })
        }
    }
    submitBlackListForm = async (event) => {
        event.preventDefault();
        let resetTempMaxAttemptdata =this.state.resetMaxAttemptdata;
        var thisObj = this;
        if (this.validFormData()) {
            const response = await MasterService.post('dealer/user-manager/reset_max_attempt', { ...this.state.resetMaxAttemptdata });
            if (response.data.status == 200) {
                resetTempMaxAttemptdata['user_name']='';
                toast.success(response.data.message);
                thisObj.setState({ resetTempMaxAttemptdata }, () => { });
            } else {
                toast.error(response.data.message);
            }
        }
    }
    validFormData = () => {
        let resetMaxAttemptdata = this.state.resetMaxAttemptdata;
        let errors = {};
        let formIsValid = true;
        if (!resetMaxAttemptdata.user_name) {
            errors['user_name'] = this.props.t('Listing.Reset_Account_Block.User_Name_Required');
            formIsValid = false;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleChangetext = (event) => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let resetMaxAttemptdata = this.state.resetMaxAttemptdata;
        let errors = this.state.errors;
        if (fieldRequired === 'yes') {
            if (event.target.value === '') {
                errors[event.target.name] = this.props.t('Listing.Blacklist_Popup.blacklist_comment_required');
            } else {
                delete errors[event.target.name];
            }
        }
        resetMaxAttemptdata[event.target.name] = event.target.value;
        this.setState({ resetMaxAttemptdata }, () => { });
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }
        const {resetMaxAttemptdata, errors } = this.state;
        return (
            <div className={(this.state.pageloading) ? "container-fluid loading" : "container-fluid"}>
                <h1>{this.props.t('Listing.Reset_Account_Block.Reset_Account_Block_Title')}</h1>
                <div className="card">
                    <div className="card-body">
                        <div className="reset-account-block">
                            
                            <div className="row">
                                <div className="col-sm-3 col-md-3 form-group">
                                    <InputField
                                        inputProps={{
                                            id: "user_name",
                                            type: "text",
                                            placeholder: this.props.t('Listing.Reset_Account_Block.User_Name_Title'),
                                            name: "user_name",
                                            autocompleate: "off",
                                            label: this.props.t('Listing.Reset_Account_Block.User_Name_Title'),
                                            validationreq: "yes",
                                            validation_error: errors.user_name,
                                            label_before_input: 'true',
                                            value:resetMaxAttemptdata.user_name
                                        }}
                                        onChange={this.handleChangetext}
                                    />
                                    <Button onClick={this.submitBlackListForm} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title={this.props.t('Listing.Blacklist_Popup.Save')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(ResetAccountBlock));

