import React, { Component } from 'react';
import { Route } from 'react-router';
// import Orders from './orders.component';
// import Transactions from './transactions.component'
import DealerList from './../view/dealer/component/dealer-list';
import ManagerList from './../view/accountManager/component/account-manager-list';
import AddAccountManager from './../view/accountManager/component/add-account-manager';
import ResetAccountBlock from './../view/dealer/component/ResetAccountBlock';
import SuperAdminReport from './../view/dealer/component/SuperAdminReport';
// import NotFound from './../view/common/NotFound';

class AdminRoute extends Component {
    render() {
        const { match: { path } } = this.props;
        console.log("path", path);
        return (
            <div>
                <Route path={`${path}/dealer-list`} component={DealerList} />
                <Route path={`${path}/reset-account-block`} component={ResetAccountBlock} />
                <Route path={`${path}/super-admin-report`} component={SuperAdminReport} />
                <Route path={`${path}/ac-manager`} component={ManagerList} />
                <Route path={`${path}/add-manager`} exact component={AddAccountManager} />
                <Route path={`${path}/add-manager/:ac_manager_id`} component={AddAccountManager} />

                {/* <Route path="/*"
                    component={NotFound} /> */}
            </div>
        );
    }
}

export default AdminRoute;