import React, { Component } from "react";
import InputField from './../../elements/InputField'
import Select from 'react-select';
import Button from '../../elements/Button'
import DatePicker from "react-datepicker";
import DateFormate from 'dateformat';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {MOBILE_VALIDATION} from './../../../config/constant';

class AddBuyerLead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                car_id: [props.carId],
                dealer_id: this.props.dealer_id
            },
            errors: {},
            sourceList: [],
            statusList: [],
            budgetList: []
        };
    }

    componentDidMount = () => {
        this.loadAllComponentData();        
    }

    loadAllComponentData = async () => {
        this.loadSourceList();
        this.loadStatusList();
        this.loadBudgetList();
    }

    loadStatusList = () => {
        var parentObj = this;
        MasterService.get('lead/lead/getAllStatus')
            .then(function (response) {
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({
                        statusList: response.data.data
                    })
                }
            }).catch(function (error) {
            });
    }

    loadSourceList = () => {
        var parentObj = this;
        MasterService.get('lead/lead/getAllSource')
            .then(function (response) {
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({
                        sourceList: response.data.data
                    })
                }
            }).catch(function (error) {

            });
    }

    loadBudgetList = () => {
        var parentObj = this;
        MasterService.get('lead/lead/getAllBudget')
            .then(function (response) {
                if (response.status == 200 && response.data.status == 200) {
                    parentObj.setState({
                        budgetList: response.data.data
                    })
                }
            }).catch(function (error) {
            });
    }

    handleDateChange = (date, target_name) => {
        let formatedDate = DateFormate(date, 'yyyy-mm-dd hh:mm TT');
        let formData = this.state.formData;
        let errors = this.state.errors;
        if (target_name === 'followup_date') {
            let currentDate = new Date();
            if (date === '') {
                errors[target_name] = target_name + " is required";
            }else if(currentDate > new Date(date)){
                errors[target_name] = this.props.t('validation.future_date_only');
            } else {
                delete errors[target_name];
            }
        }
        formData[target_name] = formatedDate;
        this.setState({
            formData,
            errors,
            [target_name]: date
        });
    }

    handleChange = (sname, ovalue) => {
        let formData = this.state.formData;
        let errors = this.state.errors;
        if (sname === 'lead_status' || sname === 'lead_source') {
            if (ovalue.value === '') {
                errors[sname] = sname + " is required";
            } else {
                delete errors[sname];
            }
        }
        formData[sname] = (sname === 'lead_status') ? ovalue.value : ((sname === 'cust_location') ? ovalue.id : ovalue.key);
        this.setState({
            formData,
            errors
        });
    }

    handleChangetext = (event) => {
        let fieldRequired = event.target.getAttribute('validationreq');
        let formData = this.state.formData;
        let errors = this.state.errors;
        if (fieldRequired === 'yes') {
            if (event.target.value === '') {
                errors[event.target.name] = event.target.name + " is required";
            } else {
                delete errors[event.target.name];
            }
        }
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    submitLeadForm = (event) => {
        event.preventDefault();
        if (this.validFormData()) {
            let formData = this.state.formData;
            formData.from_stock = 1;
            MasterService.post('lead/lead/createLead', formData)
                .then((response) => {
                    if (response.status == 200 && response.data.status == 200) {
                        if(response.data.data && response.data.data.length){
                            this.props.handleLeadAdd(response.data.data[0]['total_lead']);
                        }                        
                        toast.success(response.data.message);
                        this.props.modalClose();
                    }
                    else{
                        toast.error(response.data.message);
                    }
                }).catch((error) => {

                })
        }
    }

    validFormData = () => {
        let formData = this.state.formData;
        let errors = {};
        let formIsValid = true;
        if (!formData["cust_name"]) {
            formIsValid = false;
            errors["cust_name"] = "Customer name is required.";
        }
        if (!formData["cust_email"]) {
            formIsValid = false;
            errors["cust_email"] = "Email ID is required.";
        }
        else if (formData["cust_email"] && !formData["cust_email"].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            formIsValid = false;
            errors["cust_email"] = "Invalid email ID.";
        }
        if (!formData["cust_mobile"]) {
            formIsValid = false;
            errors["cust_mobile"] = "Customer mobile is required.";
        } else if (!MOBILE_VALIDATION.test(formData["cust_mobile"])) {
            formIsValid = false;
            errors["cust_mobile"] = "Invalid mobile.";
        }
        if (!formData["lead_source"]) {
            formIsValid = false;
            errors["lead_source"] = "Source is required.";
        }
        if (!formData["lead_status"]) {
            formIsValid = false;
            errors["lead_status"] = "Status is required.";
        }
        if (!formData["followup_date"]) {
            formIsValid = false;
            errors["followup_date"] = "Followup is required.";
        }else if(new Date() > new Date(formData["followup_date"])){
            formIsValid = false;
            errors["followup_date"] = this.props.t('validation.future_date_only');
        }
        this.setState({ errors: errors })
        return formIsValid;
    }

    render() {
        const { sourceList, statusList, budgetList, formData, errors } = this.state;
        let css = '';
        if (errors) {
            css = `.form-error {display:block}`;
        }        
        
        return (
            <div>
                <style type="text/css">
                    {css}
                </style>
                <div className="row">
                    <div className="col-sm-6 col-md-6">
                        <InputField
                            inputProps={{
                                id: "cust_name",
                                type: "text",
                                placeholder: this.props.t('listLead.Customer_Name'),
                                name: "cust_name",
                                autocompleate: "off",
                                label: this.props.t('listLead.Customer_Name'),
                                validationreq: "yes",
                                validation_error: errors.cust_name
                            }}
                            onChange={this.handleChangetext}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <InputField
                            inputProps={{
                                id: "cust_mobile",
                                type: "tel",
                                placeholder: this.props.t('listLead.Mobile'),
                                name: "cust_mobile",
                                autocompleate: "off",
                                label: this.props.t('listLead.Mobile_No'),
                                validationreq: "yes",
                                validation_error: errors.cust_mobile,
                                maxLength: 12
                            }}
                            onChange={this.handleChangetext}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6">
                        <InputField
                            inputProps={{
                                id: "cust_email",
                                type: "text",
                                placeholder: this.props.t('listLead.Email'),
                                name: "cust_email",
                                autocompleate: "off",
                                label: this.props.t('listLead.Customer_Email'),
                                validationreq: "yes",
                                validation_error: errors.cust_email
                            }}
                            onChange={this.handleChangetext}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 form-group">
                        <label>{ this.props.t('listLead.Source') }*</label>
                        <Select
                            id="lead_source"
                            value={formData.lead_source}
                            onChange={this.handleChange.bind(this, 'lead_source')}
                            options={sourceList}
                            name="lead_source"
                            placeholder={ this.props.t('listLead.Source') }
                            value={sourceList.filter(({ key }) => key === formData.lead_source)}
                            getOptionLabel={({ value }) => value}
                            getOptionValue={({ key }) => key}
                        />
                        <span className="form-error">{errors.lead_source}</span>
                    </div>
                    <div className="col-sm-6 col-md-6 form-group">
                        <label>{ this.props.t('listLead.Select_budget') }</label>
                        <Select
                            id="budget"
                            value={formData.budget}
                            onChange={this.handleChange.bind(this, 'budget')}
                            options={budgetList}
                            name="budget"
                            placeholder={ this.props.t('listLead.Select_budget') }
                            value={budgetList.filter(({ key }) => key === formData.budget)}
                            getOptionLabel={({ value }) => value}
                            getOptionValue={({ key }) => key}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 form-group">
                        <label>{ this.props.t('listLead.status') }*</label>
                        <Select
                            id="lead_status"
                            value={formData.lead_status}
                            onChange={this.handleChange.bind(this, 'lead_status')}
                            options={statusList.filter(data=>data.key != 1)}
                            name="lead_status"
                            placeholder={ this.props.t('listLead.status') }
                            value={statusList.filter(({ value }) => value === formData.lead_status)}
                            getOptionLabel={({ value }) => value}
                            getOptionValue={({ key }) => key}
                        />
                        <span className="form-error">{errors.lead_status}</span>
                    </div>


                    <div className="col-sm-6 col-md-6 form-group">
                        <label>{ this.props.t('listLead.followup_date') }*</label>
                        <DatePicker
                            selected={this.state.followup_date}
                            showTimeSelect
                            id="followup_date"
                            name="followup_date"
                            dateFormat="yyyy-MM-dd h:mm aa"
                            onChange={(date) => this.handleDateChange(date, 'followup_date')}
                            minDate={new Date()}
                            className="form-control"
                            autocompleate="off"
                            placeholderText={ this.props.t('listLead.followup_date') }
                            isClearable
                        />
                        <span className="form-error">{errors.followup_date}</span>
                    </div>

                    <div className="col-sm-6 col-md-12 form-group">
                        <InputField
                            inputProps={{
                                id: "lead_comment",
                                type: "text",
                                placeholder: this.props.t('listLead.Comments'),
                                name: "lead_comment",
                                autocompleate: "off",
                                label: this.props.t('listLead.Comments')
                            }}
                            onChange={this.handleChangetext}
                        />
                    </div>

                    <div className="col-sm-6 col-md-12 form-group text-right">
                        <Button onClick={this.props.modalClose} colclass="col-sm-12" btnClass="btn btn-link mrg-r15" type="reset" name="addleadbtn" id="cancel" title={this.props.t('listLead.Cancel')} />
                        <Button onClick={this.submitLeadForm} colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title={this.props.t('listLead.Save')} />
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

export default withTranslation('lead')(connect(mapStateToProps)(AddBuyerLead));