import React, { Component } from "react";
import InputField from '../../elements/InputField';
import Button from '../../elements/Button';
import { withTranslation } from 'react-i18next';
import { MOBILE_VALIDATION } from './../../../config/constant';
import MasterService from "../../../service/MasterService";
import { toast } from 'react-toastify';

class ShareDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showsms: false,
            email: '',
            mobile: '',
            errors: [],
            loading: false
        }
    }
    showSms = () => {
        this.setState({ showsms: true });
    }
    showEmail = () => {
        this.setState({ showsms: false });
    }
    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    sendDetails = () => {
        let errors = [];
        let formIsValid = true;
        if (this.state.showsms == true) {
            if (!this.state.share_mobile) {
                formIsValid = false;
                errors["share_mobile"] = this.props.t('addInventory.validation_error.mobile_required');
            } else if (!MOBILE_VALIDATION.test(this.state.share_mobile)) {
                formIsValid = false;
                errors["share_mobile"] = this.props.t('addInventory.validation_error.invalid_mobile');
            }
        }
        else {
            if (!this.state.share_email) {
                formIsValid = false;
                errors["share_email"] = this.props.t('addInventory.validation_error.email_required');
            } else if (this.state.share_email && !this.state.share_email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                formIsValid = false;
                errors["share_email"] = this.props.t('addInventory.validation_error.invalid_email');
            }
        }

        if (formIsValid == false) {
            this.setState({
                errors: errors
            });
        }
        else {
            let share_url = 'inventory/inventory/share_cardetail_via_email';
            let postObj = {stock_used_car_id: this.props.carId}
            if (this.state.showsms == true) {
                share_url = 'inventory/inventory/share_cardetail_via_sms';
                postObj.mobile = this.state.share_mobile;
            }
            else{
                postObj.email_id = this.state.share_email;
            }
            
            this.setState({
                loading:true
            }, () => {
                this.sendEmailSms(share_url, postObj);
            })                       
        }
    }

    sendEmailSms = (share_url, postObj) => {
        MasterService.post(share_url, postObj)
        .then(response => {
            this.setState({loading:false});
            if (response.data.status == 200) {
                toast.success(response.data.message);
                this.props.modalClose();
            } else {
                toast.error(response.data.message);
                this.props.modalClose();
            }
        })
        .catch(function (response) {
        });
    }
    

    render() {
        const { errors } = this.state;
        return (
            <div className={this.state.loading ? "loading" : "formHeight"}>
                <ul className="list-inline nav-tabs mrg-b15">
                    <li className={this.state.showsms == false ? "nav-item active" : "nav-item"} onClick={this.showEmail}><div>{this.props.t('listInventory.by_email')}</div></li>
                    <li className={this.state.showsms == true ? "nav-item active" : "nav-item"} onClick={this.showSms}><div>{this.props.t('listInventory.by_sms')}</div></li>
                </ul>
                {this.state.showsms == false ? <div className="" id="byemail">
                    <InputField
                        inputProps={{
                            id: "share_email",
                            type: "email",
                            placeholder: this.props.t('listInventory.enter_email'),
                            name: "share_email",
                            autoComplete: "off",
                            label: this.props.t('listInventory.email'),
                            value: this.state.share_email || '',
                            dataerror: errors.share_email || ''
                        }}
                        onChange={this.handleChangetext}
                    />
                </div>
                    :
                    <div className="" id="bysms">
                        <div>
                            <InputField
                                inputProps={{
                                    id: "share_mobile",
                                    type: "text",
                                    placeholder: this.props.t('listInventory.enter_mobile'),
                                    name: "share_mobile",
                                    autoComplete: "off",
                                    label: this.props.t('listInventory.sms'),
                                    value: this.state.share_mobile || '',
                                    dataerror: errors.share_mobile || ''
                                }}
                                onChange={this.handleChangetext}
                            />
                        </div>
                    </div>
                }

                <div className="text-right">
                    <Button btnClass="btn btn-link mrg-r15" name="cancel" type="button" id="reset" onClick={this.props.modalClose} title="Cancel" />
                    <Button colclass="col-sm-12" btnClass="btn btn-primary" name="btnSubmit" onClick={this.sendDetails} id="sendemail" title={this.state.showsms == true?this.props.t('listInventory.send_sms'):this.props.t('listInventory.send_email')} />
                </div>

            </div>
        );
    }
}

export default withTranslation('inventory')(ShareDetails);