import React, { Component } from 'react';
import Select from 'react-select';
import InputField from './../../elements/InputField';
import CheckBox from './../../elements/CheckBox';
import Radiobox from './../../elements/Radiobox';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class SearchDealer extends Component {
  constructor(props) {
    super(props);
    this.handleChangetext = this.handleChangetext.bind(this);
    this.state = {
      filterData: {
      },
    };
  }


  handleChangetext = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  handleOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] = (ovalue.value != null) ? ovalue.value : ovalue.target.value;
    this.setState({ filterData });
    if (typeof this.props.optionChange === 'function') {
      this.props.optionChange(sname, ovalue);
    }

  }
  handleCityOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] = (ovalue.id != null) ? ovalue.id : ovalue.target.id;
    this.setState({ filterData });
    if (typeof this.props.optionCityChange === 'function') {
      this.props.optionCityChange(sname, ovalue);
    }

  }
  handleSkuOptionChange = (sname, ovalue) => {
    let filterData = this.state.filterData;
    filterData[sname] = (ovalue.sku_short_name != null) ? ovalue.sku_short_name : ovalue.target.sku_short_name;
    this.setState({ filterData });
    if (typeof this.props.optionSkuChange === 'function') {
      this.props.optionSkuChange(sname, ovalue);
    }

  }

  componentWillMount() {
    if (sessionStorage.getItem('userData')) {
      this.setState({ redirect: true })
    }
  }
  submitFilterForm = (event) => {
    event.preventDefault();
    if (typeof this.props.onSubmitFilter === 'function') {
      this.props.onSubmitFilter(event);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.searchByList && nextProps.searchByList[0] && !this.state.filterData.search_by) {
      this.handleOptionChange("search_by", { value: nextProps.searchByList[0].value })
    }
  }
  
  resetFilterForm = (event) => {
    window.location.reload(false); 
  }
  render() {
    const { searchByList, cityAllList, dcSkusList, dealerCountStatusDetail } = this.props;
    const { filterData } = this.state;
    return (
      <div className="search-wrap">
        <form onSubmit={this.submitFilterForm}>
        <div className="row">
          <div className="col-sm-6 col-md-2">
          <label>{this.props.t('Listing.Search_Dealer.Search_By')}</label>
            <Select
              id="search_by"
              value={filterData.search_by}
              onChange={this.handleOptionChange.bind(this, 'search_by')}
              options={searchByList}
              name="search_by"
              placeholder={this.props.t('Listing.Search_Dealer.Search_By_Placeholder')}
              value={searchByList.filter(({ value }) => value === filterData.search_by)}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
          </div>

          <div className="col-sm-6 col-md-2">
            <InputField
              inputProps={{
                id: "search_text",
                type: "text",
                placeholder: this.props.t('Listing.Search_Dealer.Enter_Keyword_Placeholder'),
                name: "search_text",
                autocompleate: "off",
                label: this.props.t('Listing.Search_Dealer.Enter_Keyword_Label')
              }}
              onChange={this.handleOptionChange.bind(this, 'search_text')}
            />
          </div>
          <div className="col-sm-6 col-md-2">
          <label>{this.props.t('Listing.Search_Dealer.City')}</label>
            <Select
              id="city_id"
              value={filterData.city_id}
              onChange={this.handleCityOptionChange.bind(this, 'city_id')}
              options={cityAllList}
              name="city_id"
              placeholder={this.props.t('Listing.Search_Dealer.City_Placeholder')}
              value={cityAllList.filter(({ id }) => id === filterData.city_id)}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
            />
          </div>

          <div className="col-sm-6 col-md-2">
            <label>{this.props.t('Listing.Search_Dealer.Search_By_Sku')}</label>
            <Select
              id="dc_skus_id"
              value={filterData.sku_short_name}
              onChange={this.handleSkuOptionChange.bind(this, 'sku_short_name')}
              options={dcSkusList}
              name="dc_skus_id"
              placeholder={this.props.t('Listing.Search_Dealer.Search_By_Sku_Placeholder')}
              value={dcSkusList.filter(({ sku_short_name }) => sku_short_name === filterData.sku_short_name)}
              getOptionLabel={({ sku_name }) => sku_name}
              getOptionValue={({ sku_short_name }) => sku_short_name}
            />
          </div>

          <div className="col-sm-6 col-md-4">
            <label></label>
            <div> 
            <button type="submit" className="btn btn-primary mrg-r10">{this.props.t('Listing.Search_Dealer.Search')}</button>
            <button type="reset" className="btn btn-default" onClick={this.resetFilterForm}>{this.props.t('Listing.Search_Dealer.Reset')}</button>
            </div>
          </div>
        </div>
        </form>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
      auth_token: state.authToken,
      user_loggedin: state.userLoggedIn,
      dealer_id: state.dealer_id
  };
}

const mapDispatchToProps = dispatch => {
  return {
      updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
  }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(SearchDealer));
//export default SearchDealer;
