import React,{Component} from 'react';
import InputField from '../../elements/InputField';
import { withTranslation } from 'react-i18next';
class OtpValidation extends Component{
    constructor(props){
        super(props);
        this.state = {
            errors: {}
        }
    }

    handleChange = event =>{
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }

    validateOTP = event =>{
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(event);
        }
    }
    componentWillReceiveProps = (nextProps)=>{
        this.setState({errors : nextProps.errors},()=>{
        });
    }
    render (){
        return (
            <form method="post" id="validate-otp-form1" className="text-left os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                <InputField
                    inputProps={{
                        id: "otp",
                        type: "tel",
                        name: "otp",
                        label:this.props.t('forgot.otp'),
                        dataerror: this.state.errors.otp,
                    }}
                    onChange={this.handleChange}
                />
                <div className="form-group">                    
                    <a href="#" className="editmobile hidden" id="inv-otp">{this.props.t('forgot.edit')}</a>
                    <span id="validateOtp" className="valid-otp"></span>
                </div>
                <div className="form-group" id="showbutton1">
                    <button type="submit" className="btn btn-primary btn-lg btn-block" id="otp-login" onClick={this.validateOTP}>{this.props.t('forgot.validate_otp')}</button>
                </div>
            </form>
        );
    }
}

export default withTranslation('common')(OtpValidation);