import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
class TabCount extends Component {
    constructor(props){
        super(props);
        this.state = {
            tabData: ''
        }
    }
    
    componentWillReceiveProps = (nextProps)=>{
        if(nextProps.tabData != null){
            this.setState({
                tabData: nextProps.tabData
            })
        }
    }

    handleTabClick = (event)=>{
        let tab_click = event.currentTarget.dataset.key;
        if (typeof this.props.handleTabClick === 'function') {
            this.props.handleTabClick(tab_click);
        }
    }
    render() {
        let {tabData} = this.state;
        return (
            <div className="navbar">
                <ul className="list-inline nav-tabs">
                    <li data-key="all" className={"nav-item " + (this.props.selectedTab === 'all' ? "active" : " ")} onClick={this.handleTabClick}><div>{this.props.t('listLead.all_leads')}<span>({tabData.alllead || 0})</span></div></li>
                    <li data-key="noaction" className={"nav-item " + (this.props.selectedTab === 'noaction' ? "active" : " ")} onClick={this.handleTabClick}><div>{this.props.t('listLead.new')}<span>({tabData.New || 0})</span></div></li>
                    <li data-key="todayfollow" className={"nav-item " + (this.props.selectedTab === 'todayfollow' ? "active" : " ")} onClick={this.handleTabClick}><div>{this.props.t('listLead.followups')}<span>({tabData.follow_up || 0})</span></div></li>
                    <li data-key="pastfollow" className={"nav-item " + (this.props.selectedTab === 'pastfollow' ? "active" : " ")} onClick={this.handleTabClick}><div>{this.props.t('listLead.walkin')}<span>({tabData.walkIn || 0})</span></div></li>
                    <li data-key="futurefollow" className={"nav-item " + (this.props.selectedTab === 'futurefollow' ? "active" : " ")} onClick={this.handleTabClick}><div>{this.props.t('listLead.booked_and_offer')}<span>({tabData.finalized || 0})</span></div></li>
                    <li data-key="converted" className={"nav-item " + (this.props.selectedTab === 'converted' ? "active" : " ")} onClick={this.handleTabClick}><div>{this.props.t('listLead.converted')}<span>({tabData.converted || 0})</span></div></li>
                    <li data-key="closed" className={"nav-item " + (this.props.selectedTab === 'closed' ? "active" : " ")} onClick={this.handleTabClick}><div>{this.props.t('listLead.closed')}<span>({tabData.closed || 0})</span></div></li>
                    <li data-key="followfuturedate" className={"nav-item " + (this.props.selectedTab === 'followfuturedate' ? "active" : " ")} onClick={this.handleTabClick}><div>{this.props.t('listLead.future_follow')}<span>({tabData.future_follow_up || 0})</span></div></li>
                </ul>
            </div>
        );
    }
}

export default withTranslation('lead')(TabCount);