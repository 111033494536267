import React, { Component } from 'react';
import { Redirect,NavLink } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import InputField from './../../elements/Input';
import RadioBox from './../../elements/Radiobox';
import CheckBox from './../../elements/CheckBox';
import Button from './../../elements/Button';
import Accordion from './../../elements/Accordion';
// import Rupees from './../../elements/Rupees';
import ModalPopup from './../../elements/Popup';
import { DealerService } from './../../../service';
import AddSKU from './AddSKU';
import SubscriptionHistoryLog from './SubscriptionHistoryLog';
import SkuBox from './SkuBox';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// import secureStorage from '../../../config/encrypt';
// import { connect } from 'react-redux';
import axios from 'axios';
import {API_URL} from './../../../config/constant';
// const role = [
//     { value: '125', label: 'Owner' },
//     { value: '227', label: 'Dealer' },
// ];


class SubscriptionDetails extends Component {

    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        
        // this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            viewonly: props.role=="dealercentral",
            key: 1,
            role: "",
            loading:true,
            startDate: new Date(),
            isOpen: false,
            showmodal: false,
            showSubscritionHistoryModal:false,
            dealer_id_hash:"",
            basic_details:{},
            usedSkus:[],
            skus: [],
            subscriptions: [],
            editDataAll: [],
            skuNew:[],
            editSkuExpired:[],
            editSkuExisting:[],
            showConfirmModal: false
        };
        this.validateskuRef=React.createRef()

    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.basic_details) this.setState({  basic_details: nextProps.basic_details });
    }
    showModal = () => {
        this.setState({ showmodal: true });
    }
    showSubscritionHistoryModal = () => {
        this.setState({ showSubscritionHistoryModal: true });
    }
    closeSubscritionHistoryModal = () => {
        this.setState({ showSubscritionHistoryModal: false });
    }
    closeModal = () => {
        this.setState({ showmodal: false });
    }

    showConfirmModal = () => {
        this.setState({ showConfirmModal: true });
    }
    closeConfirmModal = () => {
        this.setState({ showConfirmModal: false });
    }
    
    submitModal = (data) => {
        let obj = [];
        // if (data.id == 1 && data) {
        //     for (let field of data.fields) {
        //         if (field.attr_id == 43 || field.attr_id == 44)
        //             obj.push(
        //                 { attr_attribute_code: field.attr_attribute_code, bd_attr_attribute_id: field.attr_id, bd_attr_attribute_value: field.fields[0].attr_id }
        //             );
        //     }
        //     this.state.skuNew.push({master: data, "fields.sub_status": "FREE","fields.pack_type": "ACTIVE_LIST",fields:obj, _key:Math.random()});
        // }
        // else{
            this.state.skuNew.push({master: data, _key:Math.random()});
        // }
        let usedSkus = this.state.usedSkus || []
        usedSkus.push(data.id)
        this.setState({ showmodal: false, usedSkus: usedSkus });
    }
    // handleChange = date => {
    //     this.setState({
    //         startDate: date
    //     });
    // };

    handleSelect(key) {
        this.setState({ key });
    }

    // handleChange = (sname, ovalue) => {
    //     this.setState({
    //         [sname]: ovalue.value
    //     });
    // }
    handelError(err) {
        this.setState({loading:false})
    }
    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }
    componentDidMount() {
        this.setState({ dealer_id_hash: this.props.dealer_id_hash });
        if(this.props.basic_details) this.setState({ basic_details: this.props.basic_details });
        this.getBasicDetails()
    }



    getBasicDetails(){
        DealerService.getMasters(["dealer_sku"]).then((response) => {
            if (response.status == 200 && response.data.status == 200) {
                let responsedata = response.data.data || {};
                if (responsedata.dealer_sku && responsedata.dealer_sku.length) {
                    let subscM = [], skuM = [];
                    responsedata.dealer_sku.map(v => {
                        if (v.sku_type == "subscription") subscM.push(v);
                        if (v.sku_type == "service") skuM.push(v);
                    })
                    this.setState({ subscriptions: subscM ,loading:false});
                    this.setState({ skus: skuM });
                }
            }
            // return DealerService.getSkusDetails(this.state.dealer_id_hash);
            return this.fetchEditData();
        }).then((response) => {
            // if (response.status == 200 && response.data.status == 200) {
            //     if (response.data.data) {
            //         let editDataAll = response.data.data;
            //         this.setState({ editDataAll: editDataAll })
            //         this.setState({ editSkuExpired: editDataAll.filter(v => this.isSKUType(v, 'service', 'expired')).map(v => { return {...v, master:this.getMaster(v)} }) })
            //         this.setState({ editSkuExisting: editDataAll.filter(v => this.isSKUType(v, 'service', 'existing')).map(v => { return {...v, master:this.getMaster(v)} }) })
            //     }
            // }
        }).catch((error) => {
            this.setState({loading:false})
        });
    }
    getMaster = (row) => {
        return this.state.skus.filter(v => v.id == row.b_details_sku_id)[0]
    }
    isSKUType(row, type, status_type) {
        let skuInfo = this.state.skus.filter(v => {
            return (v.id == row.b_details_sku_id && v.sku_type == type && row.status_type == status_type)
        })
        return (skuInfo && skuInfo[0]) ? true : false;
    }
    fetchEditData = () => {
        return new Promise((resolve, reject) => {
            DealerService.getSkusDetails(this.state.dealer_id_hash).then((response) => {
                if (response.status == 200 && response.data.status == 200) {
                    let editDataAll = response.data.data || [];
                    let usedSkus = editDataAll.map(v => v.b_details_sku_id)
                    usedSkus = [... new Set(usedSkus)];
                    this.setState({ editDataAll: editDataAll, skuNew: [], usedSkus:usedSkus ,loading:false})
                    this.setState({ editSkuExpired: editDataAll.filter(v => this.isSKUType(v, 'service', 'expired')).map(v => { return {...v, master:this.getMaster(v), _key: Math.random()} }) })
                    this.setState({ editSkuExisting: editDataAll.filter(v => this.isSKUType(v, 'service', 'existing')).map(v => { return {...v, master:this.getMaster(v), _key: Math.random()} }) })
                    resolve();
                }
                else {
                    reject(response.data)
                }
            }).catch(reject);
        })
    }
    newSkuOnChange = (idx) => (newVal) => {
        this.state.skuNew[idx] = newVal;
        let errors = this.state.skuNew.map(v => v.errors).filter(v => v);
        // if (errors.length != 0) return toast.error(`Please fix error in form.`);
    }
    existingSkuOnSave = (idx) => (existingVal) => {
        if(existingVal && !existingVal.errors) {
            this.setState({loading:true});
            var regex = /[.,\s]/g;
            if(existingVal.b_details_selling_price) existingVal.b_details_selling_price=(''+existingVal.b_details_selling_price).replace(regex,'');
            if(existingVal['fields._vat_price']) existingVal['fields._vat_price']=(''+existingVal['fields._vat_price']).replace(regex,'');
            return DealerService.addBookingDetails([existingVal], this.state.dealer_id_hash).then(result => {
                if (result && result.data && result.data.status == 200) {
                    this.setState({loading:false})
                    if(existingVal.bulk_update != 1){                        
                        toast.success(result.data.message || "Successfully Updated")
                    }                    
                    this.fetchEditData().then().catch(error => {
                        this.handelError(error);
                    });
                }
                else if (result && result.data && result.data.status == 400) {
                    this.setState({loading:false})
                    toast.error(result.data.message || "Unable to update")
                }
            }).catch(error => {
                this.setState({loading:false})
            });
        }
        else {
            console.log("existingVal.errors", idx, existingVal)
            this.setState({loading:false})
            return toast.error(`Please fix error in form.`);
        }
    }
    showDevInfo = (event) => {
        if (event.target == event.currentTarget) this.setState({ showDevCount: (this.state.showDevCount || 0) + 1 });
    }
    expiredSkuOnSave = this.existingSkuOnSave
    expiredSkuOnChange = (idx) => (expiredVal) => {
        this.state.editSkuExpired[idx] = expiredVal;
        let errors = this.state.editSkuExpired.map(v => v.errors).filter(v => v);
        // if (errors.length != 0) return toast.error(`Please fix error in form.`);
    }
    newSkuhandelSubmit = async(event) => {
        event.preventDefault();
        // await this.validateskuRef.current.validate();
        let submit_status=1;
        let errors = this.state.skuNew.map(v => v.errors).filter(v => v);
        console.log("existingVal.errors", errors)

        if (errors.length != 0) return toast.error(`Please fix error in form.`);
        this.setState({loading:true})
        if (this.state.skuNew.length > 0) {
            var regex = /[.,\s]/g;
            this.state.skuNew.forEach((skunewdata, index) => {                
                if(skunewdata.b_details_selling_price ==null || skunewdata.b_details_selling_price ==''){
                    submit_status=0;
                }else{
                    this.state.skuNew[index].b_details_selling_price = (''+skunewdata.b_details_selling_price).replace(regex,'');
                }
                let m_attr = skunewdata.master.fields.map(v => v.attr_attribute_code);
                if(m_attr.indexOf("_vat_price") > -1){
                    if(skunewdata['fields._vat_price'] ==null || skunewdata['fields._vat_price'] ==''){
                        submit_status=0;
                    }else{
                        this.state.skuNew[index]['fields._vat_price'] = (''+skunewdata['fields._vat_price']).replace(regex,'');
                    }
                    if(parseInt(this.state.skuNew[index].b_details_selling_price) >parseInt(this.state.skuNew[index]['fields._vat_price'])){
                        submit_status=0;
                    }
                }
                if(skunewdata.b_details_validity_from==null || skunewdata.b_details_validity_from==''){
                    submit_status=0;
                }
                if(skunewdata.b_details_validity_to==null || skunewdata.b_details_validity_to==''){
                    submit_status=0;
                }
                if(!skunewdata.fields || skunewdata.fields.length==0 || !skunewdata.fields.filter(v => v.attr_attribute_code == "_no_of_listing")[0]) {
                    submit_status=0;
                    return toast.error(`Please Provide Number.`);
                }
            })
        }
        if(submit_status){
            return DealerService.addBookingDetails(this.state.skuNew, this.state.dealer_id_hash).then(result => {

                if (result && result.data && result.data.status == 200) {
                    this.setState({loading:false})
                    toast.success(result.data.message || "Successfully Created");
                    this.fetchEditData().then().catch(error => {
                        this.handelError(error);
                    });
                }
                else if (result && result.data && result.data.status == 400) {
                    this.setState({loading:false})
                    toast.error(result.data.message || "Unable to create multiple buyer listing at a time ");
                }
                else {
                    this.handelError(result.data);
                }
    
            }).catch(error => {
                
                this.handelError(error);
            });
        }else {
            this.setState({loading:false})
            return toast.error(`Please fix error in form.`);
        }
        
    }
    info_sync_bd = "Sync all subscriptions and implement in inventory."
    syncBookingDetails = () => {
        console.log("this.props.basic_details", this.props.basic_details);
        var e_sync_bd = document.getElementById("sync_bd");
        e_sync_bd.classList.add("loading");
        return DealerService.syncBookingDetails({dealer_ids: this.props.basic_details.id}).then(result => {
            e_sync_bd.classList.remove("loading");
        }).catch(error => {
            e_sync_bd.classList.remove("loading");
            console.log("5554555554 error", error)
        })

    }
    removeRowNew = (idx) => (item)=> {
        if(idx > -1 && this.state.skuNew[idx]) {
            let usedSkus = this.state.usedSkus || []
            let sku_id = this.state.skuNew[idx].master.id
            usedSkus = usedSkus.filter(id => id != sku_id);
            this.state.skuNew.splice(idx, 1)
            this.setState({
                usedSkus: usedSkus,
                skuNew: this.state.skuNew
            });
        }
    }

    expireBuyerListing = () => {   
        this.closeConfirmModal();     
        let buyer_listing_arr = [];
        this.state.editSkuExisting.map( (skuData) => {            
            if(skuData.sku_short_name == "buyer_listing" && skuData.b_details_sku_id == 1){
                //console.log(skuData);
                //return false;
                let buyer_listing_data = {...skuData}; 
                buyer_listing_data.bulk_update = 1;
                if(buyer_listing_data.fields){
                    buyer_listing_data.fields = buyer_listing_data.fields.map((listing_fields) => {
                        if(listing_fields.attr_attribute_code == 'sub_status'){
                            listing_fields.bd_attr_attribute_value = 'FREE';
                        }
                        return listing_fields;
                    })
                }                
                var d = new Date();
                d.setDate(d.getDate() - 1);
                d.setHours(23,59,59,999);
                buyer_listing_data.b_details_validity_to = d; 
                buyer_listing_arr.push(buyer_listing_data);                             
            }            
        });   

        if(buyer_listing_arr.length){
            buyer_listing_arr.map( (data, index) => {
                this.existingSkuOnSave(index)(data);  
            }) 
        } 
                
        toast.success("Successfully Updated");
    }
    // handleChangetext = (event) => {
    //     this.setState({
    //         [event.target.name]: event.target.value
    //     });
    // }
    render() {
        let basicinfo = this.state.basic_details;
        return (
        <div>
            <div className="row">
                <div className="col-sm-6">
                    <h1>{this.props.t('dealerDetails.myAccount.Subscription_Details')} <span className="f12">({basicinfo.organization} - {basicinfo.gcd_code})</span></h1>
                </div>
                {!this.state.viewonly && 
                    <div className="col-sm-6 text-right" onClick={this.showDevInfo}>
                        {this.state.showDevCount>=3 && 
                            <a id="history_model_a" onClick={this.showSubscritionHistoryModal} style={{ cursor: "pointer", color: "#0000008a" }}>{this.props.t('dealerDetails.subscription_details.subscription_history')}</a>
                        }
                        <a id="sync_bd" onClick={this.syncBookingDetails} style={{cursor:"pointer", margin:"10px 20px", color: "#0000008a"}} title={this.info_sync_bd}>sync</a>
                        <Button type="button" btnClass="btn btn-success" onClick={this.showConfirmModal}>{this.props.t('dealerDetails.subscription_details.expire_buyer_listing')}</Button>
                        &nbsp;&nbsp;
                        <Button type="button" btnClass="btn btn-success" onClick={this.showModal}>{this.props.t('dealerDetails.subscription_details.add_sku_btn')}</Button>
                    </div>
                }
            </div>

            <div className="card">
                {/* <div className="card-heading">
                    <div className="row">
                        <div className="col-sm-7"><h2 className="card-title">Subscription Details</h2></div>                        
                        <div className="col-sm-5 text-right">
                            <button type="button" className="btn btn-link" title="Other Settings" onClick={this.showModal}>+ Add SKU</button>
                        </div>
                    </div>
                </div>                     */}
                <div  className={(this.state.loading) ? "card-body loading" : "card-body"}>
                   {this.state.subscriptions.length > 0 ? 
                    <Accordion title={this.props.t('dealerDetails.subscription_details.Subscription_Services')} active="true">
                        <form name="finance_form" id="finance-0-form">
                            <div className="form-inline rc-inline">
                                <SubscriptionCheckBox subscriptionsM={this.state.subscriptions} subscriptionsEdit={this.state.editDataAll} dealer_id_hash={this.props.dealer_id_hash} viewonly={this.state.viewonly}/>
                            </div>
                        </form>
                    </Accordion>
                    : ''}

                    {this.state.skuNew.length > 0 && (
                    <Accordion title={this.props.t('dealerDetails.subscription_details.New_Sku')} active="true">
                        <div>
                            <form name="form_new_skus">
                                <div className="row">
                                    {this.state.skuNew.map((v, k) => 
                                        <SkuBox onChange={this.newSkuOnChange(k)} ref={this.validateskuRef} removeRow={this.removeRowNew(k)} key={`create_${v._key}`} sku={v} dealer_id_hash={this.get_dealer_id_hash} isOpen={true} card_type="create" />
                                    )}
                                </div>
                                <div className="card-footer text-center">
                                    {!this.state.viewonly && <Button onClick={this.newSkuhandelSubmit} type="button" btnClass="btn btn-success" ><i className="sprite ic-save mrg-r15"></i>{this.props.t('dealerDetails.subscription_details.Save')}</Button>}
                                </div>
                            </form>
                        </div>
                    </Accordion>
                    )}
                    <Accordion title={`${this.props.t('dealerDetails.subscription_details.Existing_Sku')}  (${this.state.editSkuExisting.length})`} active="true">
                        <div>
                            <form>
                                <div className="row">
                                    {this.state.editSkuExisting.map((v, k) => 
                                        <SkuBox onChange={() => {}} onSaveClick={!this.state.viewonly && this.existingSkuOnSave(k)} key={`existing${v._key}`} sku={v} dealer_id_hash={this.dealer_id_hash} card_type="existing" viewonly={this.state.viewonly}></SkuBox>
                                    )}
                                </div>
                            
                            </form>
                        </div>
                    </Accordion>
                    <Accordion title={`${this.props.t('dealerDetails.subscription_details.Expired_Sku')} (${this.state.editSkuExpired.length})`}>
                        <div>
                            <form>
                                <div className="row">
                                    {this.state.editSkuExpired.map((v, k) => 
                                        // <SkuBox onChange={() => {}} onSaveClick={!this.state.viewonly && this.expiredSkuOnSave(k)} key={`expire${v._key}`} sku={v} dealer_id_hash={this.dealer_id_hash} card_type="expire" viewonly={this.state.viewonly}></SkuBox>
                                        <SkuBox onChange={() => {}} key={`expire${v._key}`} sku={v} dealer_id_hash={this.dealer_id_hash} card_type="expire" viewonly={true}></SkuBox>
                                    )}
                                </div>
                            
                            </form>
                        </div>
                    </Accordion>

                </div>

                <div className="card-footer text-center">
                                    {/* <Button btnClass="btn btn-default mrg-r30" ><i className="sprite ic-prev-b"></i> {this.props.t('dealerDetails.Outlet_Details_master.Previous')}</Button> */}
                                    <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30">{this.props.t('dealerDetails.Outlet_Details_master.Previous')} <i className="ic-arrow_back"></i></NavLink>
                                    <NavLink to={this.props.nextpage} className="btn btn-primary">{this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></NavLink>
                    </div>
                </div>
                {this.state.showmodal == true &&
                    <ModalPopup id="EditBuyerDetails" className="modal " title={this.props.t('dealerDetails.subscription_details.Add_Subscription')} modalClose={this.closeModal}>
                        <AddSKU skus={this.state.skus} modalClose={this.closeModal} modalSubmit={this.submitModal}/>
                    </ModalPopup>
                }
                {this.state.showSubscritionHistoryModal == true &&
                    <ModalPopup id="SubscriptionHistoryLog" className="modal modal-xl" title={this.props.t('dealerDetails.subscription_details.subscription_history_title')} modalClose={this.closeSubscritionHistoryModal}>
                        <SubscriptionHistoryLog  dealer_id_hash = {this.props.dealer_id_hash} modalClose={this.closeSubscritionHistoryModal} />
                    </ModalPopup>
                }
                {this.state.showConfirmModal == true &&
                    <ModalPopup id="confirm_modal" className="modal " title={this.props.t('dealerDetails.subscription_details.expire_buyer_listing')} modalClose={this.closeConfirmModal}>
                        <div>
                            <p>{this.props.t('dealerDetails.subscription_details.expiry_confirm_msg')}</p>                            
                            <div className="col-sm-6 col-md-12 form-group text-right">
                                <Button type="button" onClick={this.expireBuyerListing} colclass="col-sm-12" btnClass="btn btn-success" name="ok" id="ok" title={this.props.t('dealerDetails.subscription_details.ok')} />
                                &nbsp;&nbsp;
                                <Button type="button" onClick={this.closeConfirmModal} colclass="col-sm-12" btnClass="btn btn-primary" name="closeconfirm" id="closeconfirm" title={this.props.t('dealerDetails.subscription_details.Cancel')} />
                            </div>
                        </div>
                    </ModalPopup>
                }
            </div>

        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetails));
//export default withTranslation('dealers')(SubscriptionDetails)

class SubscriptionCheckBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscriptionsM:[],
            subscriptionsEdit: []
        };
    }
    componentWillReceiveProps(nextProps) {
        /* set sku subscription */
        let subscriptionsM = nextProps.subscriptionsM;
        if (nextProps.subscriptionsEdit) {
            subscriptionsM = subscriptionsM.map(v => {
                let editData = nextProps.subscriptionsEdit.filter(vEdit => vEdit.b_details_sku_id == v.id)[0] || {};
                editData.master = v;
                return editData;
            });
        }
        this.setState({ subscriptionsM: subscriptionsM });
        /* set sku subscription - END */
    }
    handelError(err) {
        this.setState({loading:false})
    }
    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }
    handleChangeSubscription = (idx) => (e, v) => {
        let b_details_is_active = e.target.checked ? '1' : '0';
        v = Object.assign({}, v, { b_details_is_active: b_details_is_active });

        DealerService.saveSubscriptions(v, this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                const newSubscriptions = this.state.subscriptionsM.map((v, sidx) => {
                    if (idx !== sidx) return v;
                    return { ...v, b_details_is_active: b_details_is_active };
                });
                this.setState({ subscriptionsM: newSubscriptions });
            }
            else {
                this.handelError(result.data);
            }
        }).catch(error => {
            
        });

    }
    componentDidMount() {
        // this.setState({ dealer_id_hash: this.props.dealer_id_hash });
    }
    conformPopup(e, row) {
        let msg = e.target.checked ? `Are you sure to subscribe ${row.master.sku_name}?` : `Are you sure to un-subscribe ${row.master.sku_name}?`
        return window.confirm(msg)
    }

    render() {
        return (
                this.state.subscriptionsM.map((v, idx) => 
                    <CheckBox type="checkbox" checked={(v.b_details_is_active=='1') ? true : false}
                        name={v.master.sku_short_name} id={v.master.sku_short_name} label={v.master.sku_name} key={v.master.sku_short_name}
                        onChange={ (e) => {  if (!this.props.viewonly && this.conformPopup(e, v)) this.handleChangeSubscription(idx)(e, v)}} />
                )
        )
    }
}