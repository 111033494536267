import React, { Component } from "react";
import InputField from '../../elements/Input'
import Select from 'react-select';
import Button from '../../elements/Button'

const certification = [
    { value: '1', label: 'Carnation Certified' },
    { value: '2', label: 'Audi Approved:plus' },
    { value: '3', label: 'Car X change' },
  
  ];

class EditListing extends Component {	
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            leadsource: "",
            priceMin: "",
            priceMax: "",
            status:"",
        }
    }
    handleSelect(key) {
        this.setState({ key });
      }
    
      handleChange = (sname, ovalue) => {
        this.setState({
          [sname]: ovalue.value
        });
      }
    
      handleChangetext = (event) => {
        this.setState({
          [event.target.name]: event.target.value
        });
      }
    
	render() {
		return (
			<div>
                <form>
                <div className="alert alert-primary mrg-b15">This car has been Inspected, so only its Price and certification (if any) can be edited</div>
                <div className="">
                    <InputField id="carprice"  value="" name="carprice" label="Please enter the price of the car" type="text" placeholder="Please enter the price of the car" />
                </div>
                <div className="form-group"
                ><Select
                    id="certification"
                    value={this.state.certification}
                    onChange={this.handleChange.bind(this, 'certification')}
                    options={certification}
                    name="certification"
                    placeholder="Select a reason for car remove"
                    value={certification.filter(({ value }) => value === this.state.certification)}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    />
                    </div>
                    <div className="col-sm-6 col-md-12 form-group text-right">
                        <Button  colclass="col-sm-12" btnClass="btn btn-link" type="reset" name="addleadbtn" id="cancel" title="Cancel" />
                        <Button  colclass="col-sm-12" btnClass="btn btn-primary" name="addleadbtn" id="addleadbtn" title="Save" />
                        </div>
                        
                </form>
            </div>
		);
	}
}

export default EditListing;