import React, { Component } from 'react';
import { SITE_CONTENTS } from './../../config/constant';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
let SITE_COPYRIGHT=SITE_CONTENTS.SITE_COPYRIGHT;
class Footer extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   height: window.innerHeight}
  }
//   componentDidMount() { console.log('window inner height: ', document.documentElement.clientHeight);
//     window.addEventListener('resize', () => {
//         this.setState({
//             ht: document.documentElement.clientHeight < 700
//         });
//     }, false);
// }
  render() {
    //const className = this.state.ht ? 'footer-fixed' : '';
    return (
      <footer>
          <div className="footer-container clearfix" id="footer">
            <div className="pull-left">
              <ul className="footer-dealer">
                <li><i className="fa fa-copyright" aria-hidden="true">
                  </i>
                  {this.props.t('site_content.'+SITE_COPYRIGHT)}    
                  </li>
                {/*
                <span className="pad-L5 pad-R5">|</span>
                <li><Link to="/terms" target="_blank">{this.props.t('site_content.terms_condition')}</Link></li>
                <span className="pad-L5 pad-R5">|</span>
                <li>
                  <a href="#" data-target="#feedBack" data-toggle="modal">{this.props.t('site_content.feedback')}</a>
                  </li>
                */}
              </ul>
            </div>
            <div className="pull-right">
              <span className="f-image"><img src={require("./assets/images/phone.svg")} alt="call-img" /><span className="font-12 pad-L5">{SITE_CONTENTS.DEALER_SUPPORT_PHONE}</span><span className="pad-L5 pad-R5">|</span></span>
              <span className="f-image"><img src={require("./assets/images/email.svg")} alt="mail-img" /> <a href={"mailto:"+SITE_CONTENTS.DEALER_SUPPORT_EMAIL+"?Subject=Dealer Central Feedback"} target="_top">{SITE_CONTENTS.DEALER_SUPPORT_EMAIL}</a></span>
            </div>
          </div>
      </footer>
    );
  }
}
export default withTranslation('common')(Footer);