import React, { Component } from 'react';
import Select from 'react-select';
import InputField from '../../elements/InputField';
import MmvDropDown from './MmvDropDown';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';

class SearchStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fuel_type_id_data: [],
      min_car_price_data: [], 
      max_car_price_data: [],
      max_km_driven_data: [], 
      uc_transmission_id_data: [], 
      min_km_driven_data: [],
      loaderClass: '',
      stockFilter: {}
    };
  }

  componentDidMount = async ()=>{
    await this.getFilterList();
  }

  getFilterList = async ()=>{
    var parentObj = this;
    await MasterService.get('inventory/inventory/getfilter',null).then((response)=>{
      if(response.status == 200 && response.data.status == 200){
        let responseData = response.data.data;
        for(let index in responseData){
          let keyValue = responseData[index]['key'];
          if(keyValue === 'uc_transmission_id' || keyValue === 'fuel_type_id' || keyValue === 'min_km_driven' || keyValue === 'max_km_driven' || keyValue === 'min_car_price' || keyValue === 'max_car_price'){
            parentObj.setState({
              [keyValue+'_data'] : responseData[index]['list']
            });
          }
        }
      }
    });
  }

  handleChange = (sname, ovalue) => {
    let stockFilter = {...this.state.stockFilter};
    var field_value = (sname === 'car_make' || sname === 'car_model') ? ovalue.id : ovalue.key
    stockFilter[sname] = field_value;
    this.setState({ stockFilter: stockFilter });
    if (typeof this.props.handleStockFilter === 'function') {
      this.props.handleStockFilter(sname, field_value);
    }
  }
  handleInputChange = (event) => {
    let stockFilter = {...this.state.stockFilter};
    stockFilter[event.target.name] = event.target.value;
    this.setState({stockFilter : stockFilter});
    if (typeof this.props.handleStockFilter === 'function') {
      this.props.handleStockFilter(event.target.name, event.target.value);
    }
  }

  submitFilterForm = async (event)=>{
    event.preventDefault();
    this.setState({loaderClass: 'loading'});
    if (typeof this.props.submitFilterForm === 'function') {
     await this.props.submitFilterForm();
     this.setState({loaderClass: ''});
    }
  }
  resetFormFilter = ()=>{
    this.setState({stockFilter: {}});
    if (typeof this.props.resetFormFilter === 'function') {
      this.props.resetFormFilter();
    }
  }

  render() {
    const {fuel_type_id_data, min_car_price_data, max_car_price_data, max_km_driven_data, uc_transmission_id_data, min_km_driven_data, stockFilter } = this.state;
    return (
      <div className="search-wrap">
        <div className="row">
          <div className="col-sm-6 col-md-2">
            <InputField
              inputProps={{
                id: "keyword",
                type: "text",
                placeholder: this.props.t('listLead.Search_By_Reg_No'),
                name: "keyword",
                autocompleate: "off",
                label: this.props.t('listLead.Search_By_Reg_No'),
                value: stockFilter.keyword || ''
              }}
              onChange={this.handleInputChange}
            />
          </div>
          <div className="col-sm-6 col-md-2">
            <MmvDropDown coloumnClass="col-sm-6 form-group" handleOptionChange={this.handleChange} />
          </div>
          <div className="col-sm-6 col-md-2 form-group">
            <label>{this.props.t('listLead.Select_Price_Range')}</label>
            <div className="row">
              <div className="col-xs-6">
                <Select
                  id="min_price"
                  value={stockFilter.min_prices}
                  onChange={this.handleChange.bind(this, 'min_price')}
                  options={min_car_price_data}
                  name="min_price"
                  placeholder={this.props.t('listLead.Min')}
                  value={min_car_price_data.filter(({ key }) => key === stockFilter.min_price)}
                  getOptionLabel={({ value }) => value}
                  getOptionValue={({ key }) => key}
                />
              </div>
              <div className="col-xs-6">
                <Select
                  id="max_price"
                  value={stockFilter.max_price}
                  onChange={this.handleChange.bind(this, 'max_price')}
                  options={max_car_price_data}
                  name="max_price"
                  placeholder={this.props.t('listLead.Max')}
                  value={max_car_price_data.filter(({ key }) => key === stockFilter.max_price)}
                  getOptionLabel={({ value }) => value}
                  getOptionValue={({ key }) => key}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-2 form-group">
            <label>{this.props.t('listLead.Kilometers_Range')}</label>
            <div className="row">
              <div className="col-xs-6">
                <Select
                  id="min_km_driven"
                  value={stockFilter.min_km_driven}
                  onChange={this.handleChange.bind(this, 'min_km_driven')}
                  options={min_km_driven_data}
                  name="min_km_driven"
                  placeholder={this.props.t('listLead.From')}
                  value={min_km_driven_data.filter(({ key }) => key === stockFilter.min_km_driven)}
                  getOptionLabel={({ value }) => value}
                  getOptionValue={({ key }) => key}
                />
              </div>
              <div className="col-xs-6">
                <Select
                  id="max_km_driven"
                  value={stockFilter.max_km_driven}
                  onChange={this.handleChange.bind(this, 'max_km_driven')}
                  options={max_km_driven_data}
                  name="max_km_driven"
                  placeholder={this.props.t('listLead.To')}
                  value={max_km_driven_data.filter(({ key }) => key === stockFilter.max_km_driven)}
                  getOptionLabel={({ value }) => value}
                  getOptionValue={({ key }) => key}
                />
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-1 form-group">
            <label>{this.props.t('listLead.Fuel_Type')}</label>
            <div>
              <Select
                id="fuel_type_id"
                value={stockFilter.fuel_type_id}
                onChange={this.handleChange.bind(this, 'fuel_type_id')}
                options={fuel_type_id_data}
                name="fuel_type_id"
                placeholder={this.props.t('listLead.Fuel')}
                value={fuel_type_id_data.filter(({ key }) => key === stockFilter.fuel_type_id)}
                getOptionLabel={({ value }) => value}
                getOptionValue={({ key }) => key}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-1 form-group">
            <label>{this.props.t('listLead.Transmission')}</label>
            <div>
              <Select
                id="uc_transmission_id"
                value={stockFilter.uc_transmission_id}
                onChange={this.handleChange.bind(this, 'uc_transmission_id')}
                options={uc_transmission_id_data}
                name="uc_transmission_id"
                placeholder= {this.props.t('listLead.Transmission')}
                value={uc_transmission_id_data.filter(({ key }) => key === stockFilter.uc_transmission_id)}
                getOptionLabel={({ value }) => value}
                getOptionValue={({ key }) => key}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-2 form-group">
            <label></label>
            <div> 
              <button type="submit" onClick={this.submitFilterForm} className={"btn btn-primary mrg-r10 buyerEnquiryBtn " + this.state.loaderClass }>{this.props.t('listLead.Search')}</button>
              <button type="reset" className="btn btn-default buyerEnquiryBtn" onClick={this.resetFormFilter}>{this.props.t('listLead.reset')}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation('lead')(SearchStock);
