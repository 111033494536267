import React, { Component } from 'react';
import InputField from '../../elements/InputField';
import Select from 'react-select';
import MasterService from './../../../service/MasterService';
import axios from 'axios';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import {MOBILE_VALIDATION} from './../../../config/constant';
import {CONF_VAL} from './../../../config/constant';

const purpose = [
    {key: 'I want to become a Gaadi Affiliate Member', value: 'I want to become a Gaadi Affiliate Member'},
    {key: 'I want to participate in Trade-in Auctions', value: 'I want to participate in Trade-in Auctions'},
    {key: 'I want to Take Certification', value: 'I want to Take Certification'},
    {key: 'I want to know more about Dealer Solutions', value: 'I want to know more about Dealer Solutions'},
    {key: 'I have a complaint', value: 'I have a complaint'},
    {key: 'I have an appreciation', value: 'I have an appreciation'},
    {key: 'Other', value: 'Other'},
];

class JoinUs extends Component {
    constructor(props){
        super(props);
        this.state = {
            formData: {},
            cityList: [],
            errors: {}
        }
    }

   /* componentDidMount = ()=>{
        this.getCityList();
    }

    getCityList = ()=>{
        MasterService.get('core/commonservice/state_city_all').then((response)=>{
            if(response.status === 200 && response.data.status === 200){
                let cityList = response.data.data.city;
                this.setState({cityList: cityList});
            }
        });
    }*/

    handleInput = (event)=>{
        let fieldRequired = event.target.getAttribute('validationreq');
        let formData = {...this.state.formData};
        let errors = {...this.state.errors};
        if (event.target.name === "comment_feedback") {
            event.target.value = event.target.value.replace(/[^a-z ,.!]/gi, "");
        } else if (event.target.name === "dealership_name" || event.target.name == "name") {
            event.target.value = event.target.value.replace(/[^a-z ]/gi, "");
        }
        if(fieldRequired === 'yes'){
            if(event.target.value === ''){
                errors[event.target.name] = "Please enter "+event.target.name;
            }else{
                delete errors[event.target.name];
            }
        }
        formData[event.target.name] = event.target.value;
        this.setState({formData: formData, errors: errors});
    }

    handleChangeSelect = (field_name, fieldObj)=>{
        let formData = {...this.state.formData};
        formData[field_name] = (field_name==='city') ? fieldObj.name :fieldObj.key;
        this.setState({formData: formData});
    }

    submitFormData = (event)=>{
        event.preventDefault();
        if(this.validateForm()){
            let formData = {...this.state.formData};
            MasterService.post('lead/enquiry/saveEnquiry',formData).then((response)=>{
                if(response.status == 200 && response.data.status==200){
                    this.setState({formData: {}, errors: {}});
                    toast.success(response.data.message);
                }
            });
        }
    }

    validateForm = ()=>{
        let validForm = true;
        let errors = {...this.state.errors};
        let formData = {...this.state.formData};
        let mobileRule = MOBILE_VALIDATION;
        const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if( !formData['name'] || formData['name'] === ''){
            errors['name'] = this.props.t('joinus.enter_name');
            validForm = false;
        }
        if(!formData['mobile_no'] || formData['mobile_no'] === ''){
            errors['mobile_no'] = this.props.t('joinus.enter_mobile');
            validForm = false;
        }else if(!mobileRule.test(formData['mobile_no'])){
            errors['mobile_no'] = this.props.t('joinus.valid_mobile');
            validForm = false;
        }
        if (!formData["email"] || formData["email"] === "") {
        } else if (!email_regex.test(formData["email"])) {
            errors["email"] = this.props.t("joinus.valid_email");
            validForm = false;
        }
        this.setState({errors: errors});
        return validForm;
    }

    render() {
        const {formData, cityList, errors} = this.state;
        return (
            <div className="item active">
                <form name="rightfrm" id="rightfrm" method="post" action="" >
                    <div className="row os-animation animated fadeInUp">
                        <div className="col-md-12" id="modified_design">
                            <div className="signUp-container text-left">
                                <h1 className="login-h1">{this.props.t('joinus.join_us')}</h1>
                                <p className="login-p">{this.props.t('joinus.join_title')}</p>
                                <div className="row">
                                    <div className="col-md-6 col-R-padding">
                                        <div className="form-group">
                                            <label>{this.props.t('joinus.purpose')}</label>
                                            <Select
                                                id="purpose"
                                                // value={formData.purpose}
                                                onChange={this.handleChangeSelect.bind(this, 'purpose')}
                                                options={purpose}
                                                name="purpose"
                                                placeholder={this.props.t('joinus.purpose')}
                                                value={purpose.filter(({ key }) => key === formData.purpose)}
                                                getOptionLabel={({ value }) => value}
                                                getOptionValue={({ key }) => key}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            inputProps={{
                                                id: "name",
                                                type: "text",
                                                name: "name",
                                                label: this.props.t('joinus.name'),
                                                value: formData.name || '',
                                                dataerror: errors.name || '',
                                                validationreq: "true",
                                                label_before_input: "true"
                                            }}
                                            onChange={this.handleInput}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            inputProps={{
                                                id: "dealership_name",
                                                type: "text",
                                                name: "dealership_name",
                                                label: this.props.t('joinus.dealership_name'),
                                                value: formData.dealership_name || '',
                                                dataerror: errors.dealership_name || ''
                                            }}
                                            onChange={this.handleInput}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            inputProps={{
                                                id: "mobile_no",
                                                type: "tel",
                                                name: "mobile_no",
                                                label: this.props.t('joinus.contact_no'),
                                                value: formData.mobile_no || '',
                                                maxLength: 12,
                                                dataerror: errors.mobile_no || '',
                                                validationreq: "true",
                                                label_before_input: "true"
                                            }}
                                            onChange={this.handleInput}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            inputProps={{
                                                id: "email",
                                                type: "email",
                                                name: "email",
                                                label: this.props.t('joinus.email'),
                                                value: formData.email || '',
                                                dataerror: errors.email || ''
                                            }}
                                            onChange={this.handleInput}
                                        />
                                    </div>
                                    <div className="col-md-6 col-L-padding">
                                        <div className="form-group">
                                            <label>{this.props.t('joinus.city')}</label>
                                            <Select
                                                id="city"
                                                // value={formData.city}
                                                onChange={this.handleChangeSelect.bind(this, 'city')}
                                                options={cityList}
                                                name="city"
                                                placeholder={this.props.t('joinus.city')}
                                                value={cityList && cityList.filter(({ name }) => name === formData.city)}
                                                getOptionLabel={({ name }) => name}
                                                getOptionValue={({ id }) => id}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <label>{this.props.t('joinus.comment')}</label>
                                            <textarea onChange={this.handleInput} rows="3" className="form-control input-lg sign-txtbox" id="comment_feedback" placeholder={this.props.t('joinus.comment_placeholder')} name="comment_feedback" value={formData.comment_feedback || ''}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-xs-12">
                                        <div className="form-group">
                                            <input type="button" value={this.props.t('joinus.submit')} className="btn btn-primary btn-lg btn-block" onClick={this.submitFormData} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-4">
                                        <div className="joinUs-heading">
                                            <div className="joinUsIcon">
                                                <img src={require('./../assets/images/icon-1.svg')} alt="" />
                                            </div>
                                            No. 1
                                        </div>
                                        <p className="login-p smtxt joinUs-heading heading-section">{this.props.t('joinus.'+CONF_VAL.CAR_SITE_MSG)}</p>
                                    </div>
                                    <div className="col-xs-4 pad-L0">
                                        <div className="joinUs-heading">
                                            <div className="joinUsIcon">
                                                <img src={require('./../assets/images/icon-2.svg')} alt="" />
                                            </div>{this.props.t('joinus.car_sold')}

                                        </div>
                                        <p className="login-p smtxt joinUs-heading heading-section">{this.props.t('joinus.every_minute')}</p>
                                    </div>
                                    <div className="col-md-4 pad-L0">
                                        <div className="joinUs-heading">
                                            <div className="joinUsIcon">
                                                <img src={require('./../assets/images/icon-3.svg')} alt="" />
                                            </div>
                                            {this.props.t('joinus.manage_lead')}
                                        </div>
                                        <p className="login-p mrg-L50 smtxt joinUs-heading heading-section"> {this.props.t('joinus.advance_lms')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
export default withTranslation('common')(JoinUs);