import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Redirect, NavLink } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import InputField from './../../elements/Input';
import RadioBox from './../../elements/Radiobox';
import CheckBox from './../../elements/CheckBox';
import Button from './../../elements/Button';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DealerService, InventoryService } from './../../../service';
import Q from 'q';
import { withTranslation } from 'react-i18next';
import secureStorage from './../../../config/encrypt';
import { connect } from 'react-redux';
class KycDetails extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.state = {
            key: 1,
            city_id: "",
            loading: true,
            bank_state_id: "",
            location: "",
            dealership: "",
            business: "",
            filteredcity: props.cityAllList || [],
            kyccategory: [],
            kycdoc: [],
            kycMasterlist: props.kycMasterlist || [],
            dealer_id_hash: "",
            startDate: new Date(),
            kycUploadFile: [],
            kycDealerData: {},
            kycDealerDocData: [],
            kycDealerCallVerificationData: [],
            basic_details: props.basic_details || {},
            kyc_category_id: '',
            errors: {},
            kyc_errors: [],
            cv_link_errors: {},
            bankList:[]
        };
    }
    componentDidMount = async () => {
        await this.getDealerKycDetail();
        await this.getDealerDocKycDetail();
        await this.getDealerCallVerificationKycDetail();
        await this.getBankList();
    }

    getBankList = async()=>{
        DealerService.getMasters(["bank_list"]).then((response) => {
            if (response && response.status == 200 && response.data.status == 200) {
                this.setState({ bankList: response.data.data.bank_list});
            }
        }).catch((error) => {
            this.setState({loading:false})
        });
    }

    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }
    handlebankListOptionChange = (ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_id = ovalue.bank_id;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })
    }
    handleStateOptionChange = () => (ovalue) => {
        this.state.kycDealerData.bank_state_id = ovalue.id;
        let citylist = this.state.cityAllList;
        const result = citylist && citylist.filter(cityinfo => cityinfo.state_id == ovalue.id) || [];
        this.setState({ filteredcity: result })
    }
    handleDocCategoryOptionChange = (idx) => (ovalue) => {
        let kycDealerDoc = [...this.state.kycDealerDocData];
        kycDealerDoc[idx].kyc_master_id = ovalue.id;
        this.setState({ kycDealerDocData: kycDealerDoc })
    }
    handleChange = date => {
        this.setState({
            startDate: date
        });
    };
    handleSelect(key) {
        this.setState({ key });
    }
    handledealertype = (sname, ovalue) => {
        let chkval = ovalue.target.value;
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_account_type = chkval;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })
    }

    handleChange = (sname, ovalue) => {
        this.setState({
            [sname]: ovalue.value
        });

    }
    handletKycDealerDataTextChange = (sname, ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new[sname] = ovalue.target.value;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })

    }
    handletkycDealerDocTextChange = (sname, index, ovalue) => {
        let kycMasterlist_new = [...this.state.kycDealerDocData];
        kycMasterlist_new[index][sname] = ovalue.target.value;
        this.setState({ kycDealerDocData: kycMasterlist_new }, () => {

        })

    }
    handletkycDealerCallVerificationTextChange = (sname, index, ovalue) => {
        let kycMasterlist_new = [...this.state.kycDealerCallVerificationData];
        console.log("kycMasterlist_new",kycMasterlist_new);
        kycMasterlist_new[index][sname] = ovalue.target.value;
        this.setState({ kycDealerCallVerificationData: kycMasterlist_new }, () => {
        })
    }
    
    handleCityOptionChange = (sname, ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_city_id = ovalue.id;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })

    }
    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            basic_details: nextProps.basic_details,
            cityAllList: nextProps.cityAllList,
            filteredcity: nextProps.cityAllList,
            kycMasterlist: nextProps.kycMasterlist,
        });
    }
    stringifyFormData(fd) {
        var object = {};
        fd.forEach((value, key) => {
            if (!object.hasOwnProperty(key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        return JSON.parse(JSON.stringify(object));
    }
    handleKycDocChange = (idx) => (ovalue) => {
        let kycDealerDocData_new = [...this.state.kycDealerDocData]
        kycDealerDocData_new[idx].kyc_child_id = ovalue.kyc_child_id;
        this.setState({ kycDealerDocData: kycDealerDocData_new }, () => {
        })
    }

    getDealerKycDetail = async () => {
        DealerService.getKycDetails(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                let tempkycDealerData = {};
                let address1 = '';
                let address2 = '';
                if (result.data && result.data.data && result.data.data.id != null) {
                    tempkycDealerData = result.data.data
                    if (tempkycDealerData.bank_address != '') {
                        address1 = tempkycDealerData.bank_address.split('|')[0];
                        address2 = tempkycDealerData.bank_address.split('|')[1];
                    }
                }
                tempkycDealerData.address1 = address1;
                tempkycDealerData.address2 = address2;
                this.setState({ kycDealerData: tempkycDealerData, loading: false })
            }
            else {
                this.setState({ loading: false })
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            this.setState({ loading: false })
            toast.error(error.message || "Some Error Occurred");
        });
    }
    getDealerDocKycDetail = async () => {
        DealerService.getDocKycDetails(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                this.setState({ kycDealerDocData: result.data.data }, () => { });
            }
            else {
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            toast.error(error.message || "Some Error Occurred");
        });
    }

    getDealerCallVerificationKycDetail = async () => {
        DealerService.kycDealerCallVerificationData(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                this.setState({ kycDealerCallVerificationData: result.data.data }, () => { });
            }
            else {
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            toast.error(error.message || "Some Error Occurred");
        });
    }

    
    s3UploadFiles = (allfiles, formPostdata) => {
        let dealerKycDocSaveArr = [];
        if (allfiles.length) {
            this.setState({ loading: true })
            let prom = [];
            allfiles.forEach((fileskyc, index) => {
                let filesData = fileskyc.files;
                var formData = new FormData();

                formData.append("upload_type", 'dealer_docs');
                formData.append("dealer_id", this.props.dealer_id);
                if (filesData.length) {
                    formData.append('images', filesData[0]);
                    prom.push(InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
                }
                else {
                    prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
                }
            });
            Q.allSettled(prom)
                .then((op) => {
                    op.forEach((res, index) => {
                        let response = res.value || [];
                        if (res.state == 'fulfilled') {
                            if (response.data.data.length) {
                                let tempFileName = '';
                                let tempFileUrl = '';
                                let temp_kyc_master_id = 0;
                                let dealerKycDocSaveJson = {};
                                if (formPostdata.kyccategory.length > 1) {
                                    dealerKycDocSaveJson = {
                                        "kyc_master_id": formPostdata.kyccategory[index],
                                        "kyc_child_id": formPostdata.kycdoc[index],
                                        "doc_number": (formPostdata.docnumber[index]) ? (formPostdata.docnumber[index]) : '',
                                        "comment": formPostdata.Comment[index] ? formPostdata.Comment[index] : ''
                                    }
                                    if (formPostdata.dc_dealer_kyc_doc[index] != null && formPostdata.dc_dealer_kyc_doc[index] > 0) {
                                        let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc[index];
                                        dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                    }
                                    if (formPostdata.saved_file_name[index] != null && formPostdata.saved_file_url[index]) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name[index];
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url[index];

                                    }
                                } else {
                                    dealerKycDocSaveJson = {
                                        "kyc_master_id": formPostdata.kyccategory,
                                        "kyc_child_id": formPostdata.kycdoc,
                                        "doc_number": (formPostdata.docnumber) ? formPostdata.docnumber : '',
                                        "comment": (formPostdata.Comment) ? formPostdata.Comment : ''
                                    }
                                    if (formPostdata.dc_dealer_kyc_doc != null && formPostdata.dc_dealer_kyc_doc > 0) {
                                        let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc;
                                        dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                    }
                                    if (formPostdata.saved_file_name != null && formPostdata.saved_file_url) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name;
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;
                                    }
                                }
                                if (response.data.data[0] != 'not_updated') {
                                    tempFileName = response.data.data[0].file_name;
                                    tempFileUrl = response.data.data[0].file_url;
                                    dealerKycDocSaveJson["file_name"] = tempFileName;
                                    dealerKycDocSaveJson["file_url"] = tempFileUrl;
                                }

                                dealerKycDocSaveArr.push(dealerKycDocSaveJson);
                            }
                        }
                    });
                    let postData = {
                        dealer_id_hash: this.get_dealer_id_hash,
                        docs: dealerKycDocSaveArr
                    };
                    DealerService.saveKycDocDetails(postData).then(result => {
                        if (result && result.data && result.data.status == 200) {
                            this.setState({ loading: false })
                            toast.success(result.data.message, {
                                onClose: () => window.location.reload(true)
                            });

                        }
                        else {
                            this.setState({ loading: false })
                            toast.error(result.data.message || "Some Error Occurred");
                        }
                    }).catch(error => {
                        this.setState({ loading: false })
                        toast.error(error.message || "Some Error Occurred");
                    });
                })
                .catch((error) => {
                    this.setState({ loading: false })
                    toast.error(error.message || "Some Error Occurred");
                });
        }
    }
    saveBankDetail = (event) => {
        event.preventDefault();
        var parentThis = this;
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
        if (this.validFormData(formdata)) {
            this.setState({ loading: true })
            let allfiles = document.querySelectorAll('input[type="file"]');
            let dealerKycSaveJson = {
                "dealer_id_hash": this.get_dealer_id_hash,
                "bank_id": formdata.bankname,
                "bank_address": formdata.bankaddress1 + " | " + formdata.bankaddress2,
                "bank_state_id": formdata.state_id,
                "bank_city_id": formdata.city_id,
                "bank_pincode": formdata.pincode,
                "bank_account_type": formdata.account_type,
                "bank_account_no": formdata.acno,
                "bank_micr_code": formdata.micrcode
                // "bank_ifsc_code": formdata.ifsccode
            };
            
            DealerService.saveKycDetails(dealerKycSaveJson).then(result => {
                if (result && result.data && result.data.status == 200) {
                    this.setState({ loading: false })
                    toast.success(result.data.message);
                }
                else {
                    this.setState({ loading: false })
                    toast.error(result.data.message || "Some Error Occurred");
                }
            }).catch(error => {
                this.setState({ loading: false })
                toast.error(error.message || "Some Error Occurred");
            });
        }
    }
    saveKycDetail = (event) => {
        event.preventDefault();
        var parentThis = this;
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
        var formIsValid = false;
        var kyc_errors = this.state.kyc_errors;
        var error = {};
        // console.log(formdata);
        Object.keys(formdata).forEach((key) => {
            if (key == 'kyccategory' || key == 'kycdoc') {
                let key_error_msg = '';
                if (key == 'kyccategory') {
                    key_error_msg = 'key category';
                } else if (key == 'kycdoc') {
                    key_error_msg = 'key doc';
                }
                if (Array.isArray(formdata[key]) && formdata[key].length > 0) {
                    formdata[key].forEach((fileskyc, index) => {
                        let value = formdata[key][index];
                        if (!value) {
                            error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                            kyc_errors[index] = error;
                        }
                    })
                } else {
                    let value = formdata[key];
                    if (!value) {
                        error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                        kyc_errors[0] = error;
                    }
                }
            }

        })

        this.setState({ kyc_errors: kyc_errors }, () => { })
        if ((Object.keys(error).length) == 0) {
            formIsValid = true;
        }
        if (formIsValid) {
            let allfiles = document.querySelectorAll('input[type="file"]');
            this.s3UploadFiles(allfiles, formdata);
        } else {
            toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
        }

    }
    saveKycCallVerificationDetail = (event) => {
        event.preventDefault();
        var parentThis = this;
        let dealerKycCallVerificationSaveArr = [];
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
        var formIsValid = false;
        var error = {}; 
        if ((Object.keys(error).length) == 0) {
            formIsValid = true;
        }
        let dealerKycDocSaveJson = {};
        if (formIsValid) {
            if (Array.isArray(formdata.comment)) {
                for (let index = 0; index < formdata.comment.length; index++) {
                    dealerKycDocSaveJson = {
                        "comment": formdata.comment[index],
                        "link": formdata.link[index]
                    }
                    if (formdata.dc_dealer_kyc_call_verification_id[index] != null && formdata.dc_dealer_kyc_call_verification_id[index] > 0) {
                        let id = formdata.dc_dealer_kyc_call_verification_id[index];
                        dealerKycDocSaveJson.id = id;
                    }
                    // if (!dealerKycDocSaveJson.link.trim()) error[index + "_link"] = "required";
                    dealerKycCallVerificationSaveArr.push(dealerKycDocSaveJson);
                }
            } else {     
                dealerKycDocSaveJson = {
                    "comment": formdata.comment,
                    "link": formdata.link
                }            
                if (formdata.dc_dealer_kyc_call_verification_id!='') {
                    let id = formdata.dc_dealer_kyc_call_verification_id;
                    dealerKycDocSaveJson.id = id;
                }
                // if (!dealerKycDocSaveJson.link.trim()) error["0_link"] = "required";
                dealerKycCallVerificationSaveArr.push(dealerKycDocSaveJson);
            }
            let postData = {
                dealer_id_hash:this.get_dealer_id_hash,
                docs: dealerKycCallVerificationSaveArr
            };
            // if(Object.keys(error).length > 0) {
            //     this.setState({ cv_link_errors: error }, () => { });
            //     toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
            //     return;
            // }
            // this.setState({ cv_link_errors: error }, () => { });

            DealerService.saveKycCallVerificationDetails(postData).then(result => {
                if (result && result.data && result.data.status == 200) {
                    this.setState({ loading: false })
                    toast.success(result.data.message, {
                        onClose: () => window.location.reload(true)
                    });
                }
                else {
                    this.setState({ loading: false })
                    toast.error(result.data.message || "Some Error Occurred");
                }
            }).catch(error => {
                this.setState({ loading: false })
                toast.error(error.message || "Some Error Occurred");
            });
        } else {
            toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
        }
    }

    deleteKycDocRow = (idx) => {
        let dealerKycDocRow = [...this.state.kycDealerDocData];
        dealerKycDocRow.splice(idx, 1);
        this.setState({ kycDealerDocData: dealerKycDocRow });
    }
    deleteKycCallVerificationRow = (idx) => {
        let dealerKycDocRow = [...this.state.kycDealerCallVerificationData];
        dealerKycDocRow.splice(idx, 1);
        this.setState({ kycDealerCallVerificationData: dealerKycDocRow });
    }
    removecurrentdoc = (idx) => {
        let kycDealerDocData_new = [...this.state.kycDealerDocData]
        kycDealerDocData_new[idx].file_url = null;
        this.setState({ kycDealerDocData: kycDealerDocData_new }, () => {
        })
    }
    addKycRow = () => {
        let dealerKycDocRow = [...this.state.kycDealerDocData];
        let newDealerKycPush = { 'file_url': '' };
        dealerKycDocRow.push(newDealerKycPush);
        this.setState({ kycDealerDocData: dealerKycDocRow })
    }
    addKycCallVerificationRow = () => {
        let dealerKycDocRow = [...this.state.kycDealerCallVerificationData];
        let newDealerKycPush = {};
        dealerKycDocRow.push(newDealerKycPush);
        this.setState({ kycDealerCallVerificationData: dealerKycDocRow })
    }
    validFormData = (formData) => {
        let errors = {};
        let formIsValid = true;

        if (!formData['bankname']) {
            formIsValid = false;
            errors["bankname"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_name_required');
        }
        if (!formData['bankaddress1']) {
            formIsValid = false;
            errors["bankaddress1"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_address1_required');
        }
        // if (!formData['bankaddress2']) {
        //     formIsValid = false;
        //     errors["bankaddress2"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_address2_required');
        // }
        if (!formData['pincode']) {
            formIsValid = false;
            errors["pincode"] = this.props.t('dealerDetails.kyc_details.validation_texts.pincode_required');
        }
        if (!formData['acno']) {
            formIsValid = false;
            errors["acno"] = this.props.t('dealerDetails.kyc_details.validation_texts.account_number_required');
        }
        // if (!formData['micrcode']) {
        //     formIsValid = false;
        //     errors["micrcode"] = this.props.t('dealerDetails.kyc_details.validation_texts.micrcode_required');
        // }
        // if (!formData['ifsccode']) {
        //     formIsValid = false;
        //    errors["ifsccode"] = this.props.t('dealerDetails.kyc_details.validation_texts.ifsccode_required');
        // }
        if (!formData['state_id']) {
            formIsValid = false;
            errors["state_id"] = this.props.t('dealerDetails.kyc_details.validation_texts.state_required');
        }
        if (!formData['city_id']) {
            formIsValid = false;
            errors["city_id"] = this.props.t('dealerDetails.kyc_details.validation_texts.city_required');
        }
        if (!formData['account_type']) {
            formIsValid = false;
            errors["account_type"] = this.props.t('dealerDetails.kyc_details.validation_texts.account_type_required');
        }

        this.setState({ errors: errors })
        return formIsValid;
    }
    onChangeHandler = event => {
        let files = event.target.files
        let err = [] // create empty array
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf','application/wps-office.pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
        for (var x = 0; x < files.length; x++) {
            if (types.every(type => files[x].type !== type)) {
                err[x] = files[x].type + ' ' + this.props.t('dealerDetails.kyc_details.kyc_file_support_msg') + ' \n';
                // assign message to array
            }
        };
        for (var z = 0; z < err.length; z++) { // loop create toast massage
            event.target.value = null
            toast.error(err[z])
        }
        return true;
    }
    render() {
        const bankList = this.state.bankList;
        const { StateList } = this.props;
        const { filteredcity, kycMasterlist, kycDealerData, kycDealerDocData , kycDealerCallVerificationData , errors, kyc_errors } = this.state;
        const { cv_link_errors } = this.state;
        let basicinfo = this.state.basic_details;
        let masterlist = {};
        if (kycMasterlist) {
            kycMasterlist.forEach((val) => {
                masterlist['cat_' + val.id] = val.child;
            })
        }
        let role;
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                role = loginResponse.data.user_data.role;
            }
        }
        return (
            <div><h1>{this.props.t('dealerDetails.kyc_details.kyc_details_label')} <span className="f12">({basicinfo.organization} - {basicinfo.gcd_code})</span></h1>

                <div className={(this.state.loading ? "card loading" : "card")}>
                    <div className="card-heading">
                        <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.bank_detail')}</h2>
                    </div>

                    <div className="card-body">
                        <form onSubmit={this.saveBankDetail} method="post">
                            <div className="row">
                            <div className="col-sm-4 form-group">
                                    <label>{this.props.t('dealerDetails.kyc_details.bank_name')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                    <Select
                                        id="bankname"
                                        onChange={this.handlebankListOptionChange}
                                        options={bankList}
                                        name="bankname"
                                        placeholder={this.props.t('dealerDetails.kyc_details.bank_name')}
                                        value={bankList.filter(({ bank_id }) => bank_id == kycDealerData.bank_id)}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ bank_id }) => bank_id}
                                    />
                                    {errors.bankname &&
                                        <div className="error show" >{errors.bankname}</div>
                                    }
                                </div>
                                {/* <div className="col-sm-4">
                                    <InputField type="text" error={(errors.bankname) ? errors.bankname : ''} name="bankname" id="bankname" placeholder={this.props.t('dealerDetails.kyc_details.bank_name')} value={kycDealerData.bank_name} label={this.props.t('dealerDetails.kyc_details.bank_name')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_name')} required={true}/>
                                </div> */}
                                <div className="col-sm-4">
                                    <InputField type="text" error={(errors.bankaddress1) ? errors.bankaddress1 : ''} name="bankaddress1" id="bankaddress1" placeholder={this.props.t('dealerDetails.kyc_details.branch_address_line1')} value={kycDealerData.address1} label={this.props.t('dealerDetails.kyc_details.branch_address_line1')} onChange={this.handletKycDealerDataTextChange.bind(this, 'address1')} required={true}/>
                                </div>
                                <div className="col-sm-4">
                                    <InputField type="text"  name="bankaddress2" id="bankaddress2" placeholder={this.props.t('dealerDetails.kyc_details.branch_address_line2')} value={kycDealerData.address2} label={this.props.t('dealerDetails.kyc_details.branch_address_line2')} onChange={this.handletKycDealerDataTextChange.bind(this, 'address2')} />
                                </div>
                                <div className="col-sm-4 form-group">
                                    <label>{this.props.t('dealerDetails.kyc_details.state')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                    <Select
                                        id="state_id"
                                        onChange={this.handleStateOptionChange()}
                                        options={StateList}
                                        name="state_id"
                                        placeholder={this.props.t('dealerDetails.kyc_details.state')}
                                        value={StateList.filter(({ id }) => id == kycDealerData.bank_state_id)}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ id }) => id}
                                    />
                                    {errors.state_id &&
                                        <div className="error show" >{errors.state_id}</div>
                                    }
                                </div>
                                <div className="col-sm-4 form-group">
                                    <label>{this.props.t('dealerDetails.kyc_details.location_of_bank')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                    <Select
                                        id="city_id"
                                        options={filteredcity}
                                        name="city_id"
                                        placeholder={this.props.t('dealerDetails.kyc_details.location_of_bank')}
                                        value={filteredcity.filter(({ id }) => id === kycDealerData.bank_city_id)}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ id }) => id}
                                        onChange={this.handleCityOptionChange.bind(this, 'city_id')}
                                    />
                                    {errors.city_id &&
                                        <div className="error show" >{errors.city_id}</div>
                                    }
                                </div>
                                <div className="col-sm-4">
                                    <InputField type="tel" error={(errors.pincode) ? errors.pincode : ''} name="pincode" id="pincode" placeholder="Pin Code" value={kycDealerData.bank_pincode} label={this.props.t('dealerDetails.kyc_details.pincode')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_pincode')} required={true}/>
                                </div>
                                <div className="col-sm-4 form-group rc-inline">
                                    <div>{this.props.t('dealerDetails.kyc_details.type_of_account')} <span className="required" style={{ color: 'red' }}>*</span></div>
                                    <RadioBox type="radio" name="account_type" id="saving" value="saving" label={this.props.t('dealerDetails.kyc_details.saving')} checked={(kycDealerData.bank_account_type == 'saving') ? true : false} onChange={this.handledealertype.bind(this, 'account_type')} />
                                    <RadioBox type="radio" name="account_type" id="current" value="current" label={this.props.t('dealerDetails.kyc_details.current')} checked={(kycDealerData.bank_account_type == 'current') ? true : false} onChange={this.handledealertype.bind(this, 'account_type')} />
                                    {errors.account_type &&
                                        <div className="error show" >{errors.account_type}</div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <InputField type="tel" error={(errors.acno) ? errors.acno : ''} name="acno" id="acno" placeholder={this.props.t('dealerDetails.kyc_details.bank_account_number')} value={kycDealerData.bank_account_no} label={this.props.t('dealerDetails.kyc_details.bank_account_number')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_account_no')} required={true}/>
                                </div>
                                <div className="col-sm-4">
                                    <InputField type="text" error={(errors.micrcode) ? errors.micrcode : ''} name="micrcode" id="micrcode" placeholder={this.props.t('dealerDetails.kyc_details.micr_code_of_branch')} value={kycDealerData.bank_micr_code} label={this.props.t('dealerDetails.kyc_details.micr_code_of_branch')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_micr_code')} />
                                </div>
                                {/* <div className="col-sm-4">
                                    <InputField type="text" error={(errors.ifsccode) ? errors.ifsccode : ''} name="ifsccode" id="ifsccode" placeholder={this.props.t('dealerDetails.kyc_details.icfi_code')} value={kycDealerData.bank_ifsc_code} label={this.props.t('dealerDetails.kyc_details.icfi_code')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_ifsc_code')} />
                                </div> */}
                            </div>
                            <div className="card-footer text-center">
                                <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className={(this.state.loading ? "card loading" : "card")}>
                    <form onSubmit={this.saveKycDetail} method="post" encType="multipart/form-data">
                        <div className="card-heading">
                            <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.upload_kyc_document')}</h2>
                        </div>
                        <div className="card-body">
                            <p className="text-danger form-group f12">*{this.props.t('dealerDetails.kyc_details.kyc_doc_validation')}</p>

                            {
                                (kycDealerDocData.length) ?
                                    kycDealerDocData.map((kycDealerDoc, idx) =>
                                        <div className="row" key={idx}>
                                            <input type="hidden" name="dc_dealer_kyc_doc" id="dc_dealer_kyc_doc" value={(kycDealerDoc.id)?kycDealerDoc.id:''} ></input>
                                            <input type="hidden" name="saved_file_name" id="saved_file_name" value={(kycDealerDoc.file_name)?kycDealerDoc.file_name:''} ></input>
                                            <input type="hidden" name="saved_file_url" id="saved_file_url" value={(kycDealerDoc.file_url)?kycDealerDoc.file_url:''} ></input>
                                            <div className="col-sm-2">
                                                <label>{this.props.t('dealerDetails.kyc_details.kyc_category')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    id="kyccategory"
                                                    value={this.state.kyccategory}
                                                    onChange={this.handleDocCategoryOptionChange(idx)}
                                                    options={kycMasterlist}
                                                    name="kyccategory"
                                                    placeholder={this.props.t('dealerDetails.kyc_details.kyc_category')}
                                                    value={kycMasterlist.filter(({ id }) => id === kycDealerDoc.kyc_master_id)}
                                                    getOptionLabel={({ name }) => name}
                                                    getOptionValue={({ id }) => id}
                                                    //isDisabled={(kycDealerDoc.disable_status)?kycDealerDoc.disable_status:''}
                                                />
                                                {
                                                    (kyc_errors && kyc_errors[idx] && kyc_errors[idx].kyccategory) ? <span className="error show">{kyc_errors[idx].kyccategory}</span> : ''
                                                }
                                            </div>

                                            <div className="col-sm-2">
                                                <label>{this.props.t('dealerDetails.kyc_details.kyc_document')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    id="kycdoc"
                                                    onChange={this.handleKycDocChange(idx)}
                                                    options={masterlist['cat_' + kycDealerDoc.kyc_master_id]}
                                                    name="kycdoc"
                                                    placeholder={this.props.t('dealerDetails.kyc_details.kyc_document')}
                                                    value={(masterlist['cat_' + kycDealerDoc.kyc_master_id]) ? masterlist['cat_' + kycDealerDoc.kyc_master_id].filter(({ kyc_child_id }) => kyc_child_id === kycDealerDoc.kyc_child_id) : ''}
                                                    getOptionLabel={({ kyc_child_name }) => kyc_child_name}
                                                    getOptionValue={({ kyc_child_id }) => kyc_child_id}
                                                    //isDisabled={(kycDealerDoc.disable_status)?kycDealerDoc.disable_status:''}
                                                />
                                                {
                                                    (kyc_errors && kyc_errors[idx] && kyc_errors[idx].kycdoc) ? <span className="error show">{kyc_errors[idx].kycdoc}</span> : ''
                                                }
                                            </div>
                                            <div className="col-sm-2">
                                                <InputField type="text" name="docnumber" id="docnumber" placeholder={this.props.t('dealerDetails.kyc_details.document_number')} value={kycDealerDoc.doc_number} label={this.props.t('dealerDetails.kyc_details.document_number')} onChange={this.handletkycDealerDocTextChange.bind(this, 'doc_number', idx)} />
                                            </div>
                                            <div className="col-sm-2">
                                                <InputField type="text" name="Comment" id="Comment" placeholder={this.props.t('dealerDetails.kyc_details.comment')} value={kycDealerDoc.comment} label={this.props.t('dealerDetails.kyc_details.comment')} onChange={this.handletkycDealerDocTextChange.bind(this, 'comment', idx)} />
                                            </div>
                                            <div className="col-sm-3" >
                                                <label>{this.props.t('dealerDetails.kyc_details.doc_file')}</label>
                                                <input type="file" name="fileName" multiple className={
                                                    (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                        'form-control hide' : 'form-control'
                                                } onChange={this.onChangeHandler} />
                                                {
                                                    (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                        <div className="document-link">

                                                            {
                                                                (kycDealerDoc.file_url != '' && kycDealerDoc.file_url != null) ?
                                                                    <a href={kycDealerDoc.file_url} target="_blank" download>
                                                                        {
                                                                            (kycDealerDoc.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (kycDealerDoc.kyc_doc_type == 'image') ? <i className="ic-image-file icons-normal"></i> : (kycDealerDoc.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                                        }
                                                                        View File
                                                        </a>
                                                                    : ''
                                                            }
                                                            <span className="delete-docs" onClick={this.removecurrentdoc.bind(this, idx)}><i className="ic-clearclose"></i></span>
                                                        </div>
                                                        : ''
                                                }

                                            </div>
                                            <div className="col-sm-1 pad-t25">
                                            {
                                                (idx > 3) ?
                                                <Button type="button" btnClass="btn btn-link pull-right" onClick={this.deleteKycDocRow.bind(this, idx)}><i className="ic-delete icons-normal text-secondary"></i></Button>:''
                                            }
                                            </div>
                                        </div>
                                    ) : ''
                            }
                            <Button type="button" btnClass="btn btn-link" onClick={this.addKycRow}> {this.props.t('dealerDetails.kyc_details.add_more_kyc')}</Button>
                        </div>
                        {
                            (kycDealerDocData.length) ?
                                <div className="card-footer text-center">
                                    <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div>
                                </div> : ''
                        }
                    </form>
                </div>
                {
                    (role == 'admin') ?
                        <div className={(this.state.loading ? "card loading" : "card")}>
                            <form onSubmit={this.saveKycCallVerificationDetail} method="post" encType="multipart/form-data">
                                <div className="card-heading">
                                    <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.call_verification')}</h2>
                                </div>
                                <div className="card-body">
                                    {
                                        (kycDealerCallVerificationData.length) ?
                                            kycDealerCallVerificationData.map((kycDealerCallVerification, idx) =>
                                                <div className="row mrg-b15" key={idx} style={{ borderBottom: kycDealerCallVerificationData.length > idx + 1 ? "1px dashed #ccc" : "" }}>
                                                    <input type="hidden" name="dc_dealer_kyc_call_verification_id" id="dc_dealer_kyc_call_verification_id" value={(kycDealerCallVerification.id) ? kycDealerCallVerification.id : ''} ></input>
                                                    <div className="col-sm-4">
                                                        <InputField error={(cv_link_errors[idx + "_link"]) ? cv_link_errors[idx + "_link"] : ''} type="text" name="link" id="link" placeholder={this.props.t('dealerDetails.kyc_details.link')} value={kycDealerCallVerification.link} label={this.props.t('dealerDetails.kyc_details.link')} onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'link', idx)} />
                                                    </div>
                                                    <div className="col-sm-6 form-group">
                                                        <label>{this.props.t('dealerDetails.kyc_details.comment')}</label>
                                                        <textarea onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'comment', idx)} rows='2' className="form-control" name="comment" value={kycDealerCallVerification.comment}></textarea>
                                                        {/* <InputField type="text" name="comment" id="Comment" placeholder={this.props.t('dealerDetails.kyc_details.comment')} value={kycDealerCallVerification.comment} label={this.props.t('dealerDetails.kyc_details.comment')} onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'comment', idx)} /> */}
                                                    </div>
                                                    <div className="col-sm-1 pad-t25">
                                                        <Button type="button" btnClass="btn btn-link pull-right" onClick={this.deleteKycCallVerificationRow.bind(this, idx)}><i className="ic-delete icons-normal text-secondary"></i></Button>
                                                    </div>
                                                </div>
                                            ) : ''
                                    }
                                    <Button type="button" btnClass="btn btn-link" onClick={this.addKycCallVerificationRow}> {this.props.t('dealerDetails.kyc_details.add_more_call_verification')}</Button>
                                </div>
                                {
                                    (kycDealerCallVerificationData.length > 0) ?
                                        <div className="card-footer text-center">
                                            <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div>
                                        </div> : ''
                                }
                            </form>
                        </div>
                        : ''
                }
                <div className="card">
                    <div className="card-footer text-center">
                        <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30"> <i className="ic-arrow_back"></i> {this.props.t('dealerDetails.Outlet_Details_master.Previous')} </NavLink>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(KycDetails));
//export default withTranslation('dealers')(BasicDetails)